export const PERMISSION_TYPES = {
  LISTING: 'listing',
  ADD: 'add',
  DELETE: 'delete',
  EDIT: 'edit',
};

export const PERMISSIONS = {
  ATTENDANCE: 'attendence',
  BRANCH: 'branch',
  CLASS: 'class',
  CLASS_SECTION: 'class_section',
  CLASS_SECTION_SUBJECTS: 'class_section_subject',
  EXAM_INITIATOR: 'exam_initiator',
  EXAM_TASK_FOLLOWUP: 'exam_task_followup',
  FEE: 'fee',
  BILL: 'bill',
  EXPENSE: 'expense',
  EXPENSE_CATEGORY: 'expense_category',
  ROLES: 'roles',
  USER: 'user',
  NON_EXPENSE_PAYMENT: 'non_expense_payment',
  STUDENT: 'student',
  ITEM: 'item',
  STUDENT_ITEM: 'student_item',
  // CONFIGURATIONS
  COLORS: 'color',
  GROUPS: 'group',
  HOLIDAY: 'holiday',
  OCCUPATION: 'occupation',
  TASK_TO_USER: 'task_to_user',
  VILLAGE: 'village',
  STUDENT_INACTIVITY_REASON: 'student_inactivity_reason',
  REPORTS: 'reports',
  SESSION: 'session',
};
