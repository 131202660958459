import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  API_DELETE,
  API_GET,
  API_POST,
  API_PUT,
} from 'app/utils/constants/api/apiRequests';
import { setLoading, setLoadingFinished } from './loadingSlice';
import { showAlert } from './alertSlice';

const initialState = {
  sessions: [],
  isDataChange: false,
  isDataFetched: false,
};

export const getAllSessions = createAsyncThunk(
  'sessions/getAllSessions',
  async (_, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_GET('/session');

      if (status) {
        dispatch(
          showAlert({
            type: 'success',
            message: 'Sessions Fetched Successfully',
          }),
        );
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const addNewSession = createAsyncThunk(
  'sessions/addNewSession',
  async (payload, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, message } = await API_POST('/session', payload);
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllSessions());
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const updateSession = createAsyncThunk(
  'sessions/updateSession',
  async (payload, { dispatch }) => {
    dispatch(setLoading());

    const dataToSend = JSON.parse(JSON.stringify(payload));
    delete dataToSend['id'];
    try {
      const { status, data, message } = await API_PUT(
        '/session',
        payload.id,
        dataToSend,
      );
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllSessions());
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const deleteSession = createAsyncThunk(
  'sessions/deleteSession',
  async (id, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_DELETE('/session', id);
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllSessions());
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const sessionsSlice = createSlice({
  name: 'sessions',
  initialState,

  extraReducers: builder => {
    builder.addCase(getAllSessions.fulfilled, (state, { payload }) => {
      state.sessions = payload;
      state.isDataFetched = true;
    });
  },
});

export default sessionsSlice.reducer;
