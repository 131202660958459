import React, { useCallback } from 'react';

import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import Div from '@jumbo/shared/Div';
import BranchesList from 'app/shared/components/BranchesList';
import { useNavigate } from 'react-router-dom';
import { hasPermission, isSuperAdmin } from 'app/utils/appHelpers';
import { PERMISSIONS, PERMISSION_TYPES } from 'app/utils/constants/permissions';

import { useDispatch } from 'react-redux';
import { setClass } from 'app/store/features/classesSlice';

const ClassesAppSidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showAddContactDialog = useCallback(() => {
    dispatch(setClass({}));
    navigate('/app/classes/form', { state: { id: null } });
  }, [navigate]);

  return (
    <>
      <Div
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: 3,
        }}>
        <Div sx={{ flex: '1 1 auto' }}>
          <Typography variant={'h2'} mb={0.35}>
            Classes
          </Typography>
        </Div>
      </Div>
      {(isSuperAdmin() ||
        hasPermission(
          [PERMISSIONS.CLASS, PERMISSIONS.CLASS_SECTION],
          [PERMISSION_TYPES.ADD],
        )) && (
        <Button
          fullWidth
          disableElevation
          variant={'contained'}
          startIcon={<PersonAddIcon />}
          sx={{
            mb: 2,
            '& .MuiSvgIcon-root': {
              fontSize: '1.5rem',
            },
          }}
          onClick={showAddContactDialog}>
          Add New Class
        </Button>
      )}
      <BranchesList title="All Classes" identifier="classes" />
    </>
  );
};
export default React.memo(ClassesAppSidebar);
