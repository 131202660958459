import React from 'react';
import { useField } from 'formik';
import { Typography, TextField, Avatar } from '@mui/material';
import Div from '@jumbo/shared/Div';
import { getFullName } from 'app/utils/appHelpers';
import { PUBLIC_ASSETS_URL } from 'app/utils/constants/api/constants';
import StyledAutocomplete from 'app/shared/components/Autocomplete';

const JumboAutoComplete = props => {
  const [field, meta, helpers] = useField(props);
  const {
    options,
    label,
    isForStudents,
    isMulti,
    defaultValue,
    pickKeys,
    isForClasses,
  } = props;

  const errorText = meta.error && meta.touched ? meta.error : '';
  const renderOption = (props, option) => {
    return (
      <>
        {isForStudents ? (
          <li {...props}>
            <Avatar
              alt={option?.name}
              src={`${PUBLIC_ASSETS_URL}/${option?.image}`}
              sx={{ mr: 2, height: 30, width: 30 }}
            />
            {getFullName({
              first_name: option?.first_name,
              last_name: option?.last_name,
              father_name: option?.father_name,
            })}
            (
            <Typography color={'blue'} mr={0.5}>
              Branch:
            </Typography>
            {option?.branch?.name}.
            <Typography color={'green'} mx={0.5}>
              Class:
            </Typography>
            {option?.class_section?.class}.
            <Typography color={'orange'} mx={0.5}>
              Section:{' '}
            </Typography>
            {option?.class_section?.section}.
            <Typography color={'red'} mx={0.5}>
              Roll#:{' '}
            </Typography>{' '}
            {option?.roll_no})
          </li>
        ) : isForClasses ? (
          <li {...props}>
            <Avatar
              alt={option?.name}
              src={`${PUBLIC_ASSETS_URL}/${option?.image}`}
              sx={{ mr: 2, height: 30, width: 30 }}
            />
            {`${option?.class_section_title} `}(
            <Typography color={'blue'} mr={0.5}>
              Branch:
            </Typography>
            {option?.branch?.name}.
            <Typography color={'green'} mx={0.5}>
              Class:
            </Typography>
            {option?.class}.
            <Typography color={'orange'} mx={0.5}>
              Section:{' '}
            </Typography>
            {option?.section})
          </li>
        ) : (
          <li {...props}>{option.name}</li>
        )}
      </>
    );
  };

  return (
    <Div sx={{ marginBottom: 2 }}>
      <Typography color="gray" sx={{ marginTop: -1 }}>
        {label}
      </Typography>
      <StyledAutocomplete
        {...props}
        {...field}
        options={options}
        getOptionLabel={option => {
          return isForStudents
            ? getFullName({
                first_name: option?.first_name,
                last_name: option?.last_name,
                father_name: option?.father_name,
              })
            : option?.class_section_title;
        }}
        renderOption={renderOption}
        onChange={(_event, value) => {
          if (pickKeys?.length) {
            const pickedValues = pickKeys.reduce((obj, key) => {
              if (key in value) {
                obj[key] = value[key];
              }
              return obj;
            }, {});
            return helpers.setValue(pickedValues);
          } else {
            return helpers.setValue(
              isMulti
                ? value.map(item => item.id)
                : value?.value
                  ? value.value
                  : value?.id
                    ? value.id
                    : '',
            );
          }
        }}
        value={options.find(option => option.value === field.value)}
        multiple={isMulti}
        defaultValue={defaultValue}
        renderInput={params => <TextField {...params} variant="outlined" />}
      />
      {errorText && (
        <Typography color="red" variant="body2" sx={{ fontSize: '0.75rem' }}>
          {errorText}
        </Typography>
      )}
    </Div>
  );
};

export default JumboAutoComplete;
