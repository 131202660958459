import { useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import moment from 'moment';

import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import Div from '@jumbo/shared/Div';
import Button from '@jumbo/components/JumboButton';

import { schema } from '../../utils/schema';
import { addNewSession, updateSession } from 'app/store/features/sessionSlice';
import JumboSwitch from '@jumbo/components/JumboFormik/JumboSwitch';
import { Grid } from '@mui/material';

const FormComponent = ({ payload }) => {
  const dispatch = useDispatch();
  const { hideDialog } = useJumboDialog();

  const isUpdate = payload ? true : false;
  const initialValues = {
    session_name: payload ? payload.session_name : '',
    start_date: payload ? payload.start_date : '',
    end_date: payload ? payload.end_date : '',
    is_active: payload ? payload.is_active : false,
  };

  const formattedStartDate = moment(new Date(payload.start_date)).format(
    'MM-DD-YYYY',
  );
  const formattedEndDate = moment(new Date(payload.end_date)).format(
    'MM-DD-YYYY',
  );

  const addHandler = data => {
    const payload = { ...data };
    dispatch(addNewSession(payload));
  };

  const updateHandler = data => {
    const payload = {
      id: data.id,
      session_name: data.session_name,
      start_date: data.start_date,
      end_date: data.end_date,
      is_active: data.is_active,
    };
    dispatch(updateSession(payload));
  };

  const onSave = data => {
    isUpdate ? updateHandler(data) : addHandler(data);
    hideDialog();
  };
  return (
    <Formik
      validateOnChange={true}
      initialValues={payload?.id ? payload : initialValues}
      enableReinitialize={true}
      validationSchema={schema}
      onSubmit={onSave}>
      {({ isSubmitting }) => (
        <Form noValidate autoComplete="off">
          <Div
            sx={{
              '& .MuiTextField-root': {
                mb: 3,
              },
            }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <JumboTextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  name="session_name"
                  label="Session Name"
                  value={payload ? payload.session_name : ''}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <JumboTextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  name="start_date"
                  label="Session Start Date"
                  InputLabelProps={{ shrink: true }}
                  defaultValue={formattedStartDate}
                  InputProps={{
                    inputProps: { min: '1990-01-01', max: '9999-01-01' },
                  }}
                  type="date"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <JumboTextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  name="end_date"
                  label="Session End Date"
                  InputLabelProps={{ shrink: true }}
                  defaultValue={formattedEndDate}
                  InputProps={{
                    inputProps: { min: '1990-01-01', max: '9999-01-01' },
                  }}
                  type="date"
                />
              </Grid>
            </Grid>

            <JumboSwitch
              name="is_active"
              label="Is Session Active?"
              value={payload?.is_active}
            />

            <Div
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}>
              <Button
                variant="outlined"
                sx={{
                  mr: 2,
                }}
                onClick={() => hideDialog()}>
                Close
              </Button>
              <Button type="submit" variant="contained" loading={isSubmitting}>
                Save
              </Button>
            </Div>
          </Div>
        </Form>
      )}
    </Formik>
  );
};

export default FormComponent;
