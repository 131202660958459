import Colors from 'app/pages/configurations/colors';
import Groups from 'app/pages/configurations/groups';
import Holidays from 'app/pages/configurations/holidays';
import Occupations from 'app/pages/configurations/occupations';
import StudentInActivityReasons from 'app/pages/configurations/studentInActivityReasons';
import TasksToUser from 'app/pages/configurations/tasksToUser';
import Villages from 'app/pages/configurations/villages';
import Sessions from 'app/pages/configurations/sessions';

import { hasAnyPermissions } from 'app/utils/appHelpers';
import { PERMISSIONS } from 'app/utils/constants/permissions';

const configurationRoutes = [
  {
    path: ['/app/configuration/colors'],
    element: hasAnyPermissions([PERMISSIONS.COLORS]) ? <Colors /> : null,
  },
  {
    path: ['/app/configuration/groups/:category'],
    element: hasAnyPermissions([PERMISSIONS.GROUPS]) ? <Groups /> : null,
  },
  {
    path: ['/app/configuration/holidays'],
    element: hasAnyPermissions([PERMISSIONS.HOLIDAY]) ? <Holidays /> : null,
  },
  {
    path: ['/app/configuration/occupations'],
    element: hasAnyPermissions([PERMISSIONS.OCCUPATION]) ? (
      <Occupations />
    ) : null,
  },
  {
    path: ['/app/configuration/villages'],
    element: hasAnyPermissions([PERMISSIONS.VILLAGE]) ? <Villages /> : null,
  },
  {
    path: ['/app/configuration/sessions'],
    element: hasAnyPermissions([PERMISSIONS.SESSION]) ? <Sessions /> : null,
  },
  {
    path: ['/app/configuration/inactivity-reasons'],
    element: hasAnyPermissions([PERMISSIONS.STUDENT_INACTIVITY_REASON]) ? (
      <StudentInActivityReasons />
    ) : null,
  },
  {
    path: ['/app/configuration/task-to-user'],
    element: hasAnyPermissions([PERMISSIONS.TASK_TO_USER]) ? (
      <TasksToUser />
    ) : null,
  },
];

export default configurationRoutes;
