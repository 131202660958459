import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import JumboDropDown from '@jumbo/components/JumboFormik/JumboDropDown';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';

import { MEDIUM } from 'app/shared/constants';
import { getAllSessions } from 'app/store/features/sessionSlice';
import { getAllBranches } from 'app/store/features/branchSlice';
import { getAllClasses } from 'app/store/features/classesSlice';
import { getAllStudentInactivityReasons } from 'app/store/features/configurationSlice';
import { useFormikContext } from 'formik';
import { getAllVillages } from 'app/store/features/configurationSlice';
import { FormControlLabel, Switch, TextField } from '@mui/material';
import { capitalizeFirstLetter } from '../../../../../../utils/appHelpers.js';
import { useLocation } from 'react-router-dom';

const SchoolInfo = () => {
  const dispatch = useDispatch();
  const { values, setFieldValue } = useFormikContext();

  const { state } = useLocation();

  const sessions = useSelector(state => state.session.sessions);
  const sessionsFetched = useSelector(state => state.session.isDataFetched);
  const branches = useSelector(state => state.branches.branches);
  const branchesFetched = useSelector(state => state.branches.isDataFetched);
  const classes = useSelector(state => state.classes.classes);
  const classesFetched = useSelector(state => state.classes.isDataFetched);
  const inActiveReasons = useSelector(
    state => state.configuration.inActivityReasons,
  );
  const { isVillagesFetched } = useSelector(state => state.configuration);

  const isInActiveReasonsFetched = useSelector(
    state => state.configuration.isInActivityReasonsFetched,
  );

  const [isDiscount, setIsDiscount] = useState(false);
  const [isFirstMonthFee, setIsFirstMonthFee] = useState(false);
  const [classesToShow, setClassesToShow] = useState([]);

  const fetchData = useCallback(async () => {
    if (!isVillagesFetched) {
      dispatch(getAllVillages());
    }
    if (!branchesFetched) {
      dispatch(getAllBranches());
    }
    if (!classesFetched) {
      dispatch(getAllClasses());
    }
    if (!sessionsFetched) {
      dispatch(getAllSessions());
    }
    if (!isInActiveReasonsFetched) dispatch(getAllStudentInactivityReasons());
  }, [
    isVillagesFetched,
    branchesFetched,
    classesFetched,
    sessionsFetched,
    isInActiveReasonsFetched,
    dispatch,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (classesFetched) {
      const filteredClasses = classes.filter(
        item => item?.branch?.id === values.branch_id,
      );
      setClassesToShow(filteredClasses);

      const selectedClass = classes.find(
        item => item.id === values.class_section_id,
      );
      setFieldValue('recommended_fee', selectedClass?.fee_amount ?? 0);
      setFieldValue('school_code', selectedClass?.branch?.code);
      setFieldValue(
        'session_id',
        state
          ? state.session_id
          : sessions.find(session => session.is_active)?.id,
      );
    }
  }, [
    classes,
    values.class_section_id,
    values.branch_id,
    classesFetched,
    setFieldValue,
    state,
    sessions,
  ]);

  return (
    <div style={{ paddingRight: 10 }}>
      {
        <JumboDropDown
          fullWidth
          size="small"
          variant="outlined"
          name="branch_id"
          label="Branch Name"
          options={branches}
        />
      }
      <JumboDropDown
        fullWidth
        size="small"
        variant="outlined"
        name="class_section_id"
        label="Class Section"
        options={classesToShow}
      />

      <TextField
        label="School Code"
        disabled
        variant="outlined"
        value={branches?.find(branch => branch?.id === values.branch_id)?.code}
        fullWidth
        size="small"
        InputLabelProps={{ shrink: true }}
      />

      <TextField
        label="Session"
        disabled
        variant="outlined"
        value={
          state
            ? state.session.session_name
            : sessions.find(session => session.is_active)?.session_name
        }
        fullWidth
        size="small"
        InputLabelProps={{ shrink: true }}
      />

      <JumboTextField
        fullWidth
        type="number"
        size="small"
        variant="outlined"
        name="roll_no"
        label="Roll Number"
        onBlur={e => capitalizeFirstLetter(e, setFieldValue, 'roll_no')}
      />
      {!values.first_school && (
        <JumboTextField
          fullWidth
          size="small"
          variant="outlined"
          name="pre_school_name"
          label="Previous School Name"
          onBlur={e =>
            capitalizeFirstLetter(e, setFieldValue, 'pre_school_name')
          }
        />
      )}
      <JumboTextField
        fullWidth
        type="number"
        size="small"
        variant="outlined"
        name="prev_no_of_schools"
        label="No. of Previous Schools"
      />
      <JumboTextField
        fullWidth
        type="number"
        size="small"
        variant="outlined"
        name="prev_no_of_failures"
        label="No. of Previous Failures"
      />
      <JumboTextField
        fullWidth
        size="small"
        variant="outlined"
        name="caution"
        label="Caution"
        onBlur={e => capitalizeFirstLetter(e, setFieldValue, 'caution')}
      />
      <JumboDropDown
        fullWidth
        size="small"
        variant="outlined"
        name="medium"
        label="Medium"
        options={MEDIUM}
      />
      {!values.status && (
        <JumboDropDown
          fullWidth
          size="small"
          variant="outlined"
          name="inactive_reason_id"
          label="Inactive Reason"
          options={inActiveReasons}
        />
      )}

      {values.inactive_reason_id && (
        <JumboTextField
          fullWidth
          type="date"
          size="small"
          variant="outlined"
          name="inactive_from"
          label="Inactive From"
          InputLabelProps={{ shrink: true }}
          InputProps={{ inputProps: { min: '1990-01-01', max: '9999-01-01' } }}
        />
      )}

      <JumboTextField
        fullWidth
        size="small"
        variant="outlined"
        name="special_remarks"
        label="Special Remarks about Student"
        onBlur={e => capitalizeFirstLetter(e, setFieldValue, 'special_remarks')}
      />

      <TextField
        label="Recommended Fee"
        disabled
        variant="outlined"
        value={
          classes?.find(
            singleClass => singleClass?.id === values.class_section_id,
          )?.fee_amount
        }
        fullWidth
        size="small"
        InputLabelProps={{ shrink: true }}
      />

      {isFirstMonthFee && (
        <JumboTextField
          fullWidth
          size="small"
          variant="outlined"
          name="first_month_fee"
          label="First Month Fee"
        />
      )}

      {isDiscount && (
        <JumboTextField
          fullWidth
          size="small"
          variant="outlined"
          name="register_fee_discount"
          label="Register Fee Discount"
          type="number"
        />
      )}

      <JumboTextField
        fullWidth
        size="small"
        variant="outlined"
        name="sd_adjustment"
        label="SD Adjustment"
        type="number"
      />
      {!!values.sd_adjustment && (
        <JumboTextField
          fullWidth
          type="date"
          size="small"
          variant="outlined"
          name="sd_adjustment_from"
          label="SD Adjustment From"
          InputLabelProps={{ shrink: true }}
          InputProps={{ inputProps: { min: '1990-01-01', max: '9999-01-01' } }}
        />
      )}

      <FormControlLabel
        control={
          <Switch
            checked={isFirstMonthFee}
            onChange={e => setIsFirstMonthFee(e.target.checked)}
          />
        }
        label={'First Month Fee?'}
        sx={{ mb: 2 }}
      />

      <FormControlLabel
        control={
          <Switch
            checked={isDiscount}
            onChange={e => setIsDiscount(e.target.checked)}
          />
        }
        label={'Registration Fee Discount?'}
        sx={{ mb: 2 }}
      />

      <FormControlLabel
        control={
          <Switch
            checked={values.status}
            onChange={e => setFieldValue('status', e.target.checked)}
          />
        }
        label={'Is Student Active?'}
        sx={{ mb: 2 }}
      />
      <FormControlLabel
        control={
          <Switch
            checked={values.is_special_case}
            onChange={e => setFieldValue('is_special_case', e.target.checked)}
          />
        }
        label={'Is Student Special Case?'}
        sx={{ mb: 2 }}
      />
      <FormControlLabel
        control={
          <Switch
            checked={values.school_transport}
            onChange={e => setFieldValue('school_transport', e.target.checked)}
          />
        }
        label={'Is Student taking School Transport?'}
        sx={{ mb: 2 }}
      />

      <FormControlLabel
        control={
          <Switch
            checked={values.is_employee_child}
            onChange={e => setFieldValue('is_employee_child', e.target.checked)}
          />
        }
        label={'Is Student Employee Child?'}
        sx={{ mb: 2 }}
      />

      <FormControlLabel
        control={
          <Switch
            checked={values.first_school}
            onChange={e => setFieldValue('first_school', e.target.checked)}
          />
        }
        label={'Is FOTAN First School?'}
        sx={{ mb: 2 }}
      />
      <FormControlLabel
        control={
          <Switch
            checked={values.give_full_discount}
            onChange={e =>
              setFieldValue('give_full_discount', e.target.checked)
            }
          />
        }
        label={'Full Fee Discount?'}
        sx={{ mb: 2 }}
      />
    </div>
  );
};

export default SchoolInfo;
