import * as yup from 'yup';

export const schema = yup.object().shape({
  session_name: yup.string().max(50).required().label('Session Name'),
  start_date: yup
    .date()
    .required('Session Start Date is required.')
    .typeError('Session Start Date must be a valid date')
    .label('Session Start Date'),
  end_date: yup
    .date()
    .min(
      yup.ref('start_date'),
      'Session End Date must be greater than Session Start Date',
    )
    .required('Session End Date is required.')
    .typeError('Session End Date must be a valid date')
    .label('Session End Date'),
  is_active: yup.boolean().required().label('Is Active'),
});
