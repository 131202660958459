import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ListItemText, Typography, Stack, Tooltip } from '@mui/material';
import { MoreHoriz, Edit, Delete } from '@mui/icons-material';

import styled from '@emotion/styled';

import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import JumboDdMenu from '@jumbo/components/JumboDdMenu';
import Span from '@jumbo/shared/Span';
import JumboListItem from '@jumbo/components/JumboList/components/JumboListItem';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';

import { deleteStudent } from 'app/store/features/studentsSlice';
import { hasPermission } from 'app/utils/appHelpers';
import { PERMISSIONS, PERMISSION_TYPES } from 'app/utils/constants/permissions';

const Item = styled(Span)(({ theme }) => ({
  minWidth: 0,
  flexGrow: 0,
  padding: theme.spacing(0, 1),
}));

const StudentItem = ({ contact, view }) => {
  const { showDialog, hideDialog } = useJumboDialog();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const Swal = useSwalWrapper();

  const showContactDetail = React.useCallback(() => {
    navigate(`/app/student/${contact?.id}`);
  }, [navigate, contact?.id]);

  const handleDeleteStudent = contact => {
    dispatch(deleteStudent(contact));
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Class has been deleted successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
    hideDialog();
  };

  const handleItemAction = menuItem => {
    switch (menuItem.action) {
      case 'view':
        navigate(`/app/student/${contact?.id}`);
        break;
      case 'edit':
        navigate('/app/students/new', { state: contact });
        break;
      case 'delete':
        showDialog({
          variant: 'confirm',
          title: 'Are you sure about deleting this student?',
          content: "You won't be able to recover this student later",
          onYes: () => handleDeleteStudent(contact),
          onNo: hideDialog,
        });
        break;
      default:
        break;
    }
  };

  return (
    <JumboListItem
      componentElement={'div'}
      itemData={contact}
      secondaryAction={
        hasPermission(
          [PERMISSIONS.STUDENT],
          [PERMISSION_TYPES.EDIT, PERMISSION_TYPES.DELETE],
        ) && (
          <>
            <JumboDdMenu
              icon={<MoreHoriz />}
              menuItems={[
                hasPermission(
                  [PERMISSIONS.STUDENT],
                  [PERMISSION_TYPES.EDIT],
                ) && {
                  icon: <Edit />,
                  title: 'Edit',
                  action: 'edit',
                },
                hasPermission(
                  [PERMISSIONS.STUDENT],
                  [PERMISSION_TYPES.DELETE],
                ) && {
                  icon: <Delete />,
                  title: 'Delete',
                  action: 'delete',
                },
              ]}
              onClickCallback={handleItemAction}
            />
          </>
        )
      }
      sx={{
        cursor: 'pointer',
        borderTop: 1,
        borderColor: 'divider',
        '&:hover': {
          bgcolor: 'action.hover',
        },
      }}>
      <ListItemText
        onClick={showContactDetail}
        primary={
          <Stack direction={'row'} alignItems={'center'} sx={{ minWidth: 0 }}>
            <Item
              sx={{
                flexBasis: { xs: '100%', sm: '50%', md: '10%' },
              }}>
              <Tooltip title="School Code">
                <Typography
                  variant={'h5'}
                  fontSize={14}
                  lineHeight={1.25}
                  mb={0}
                  noWrap>
                  {`${contact?.school_code}`}
                </Typography>
              </Tooltip>
            </Item>
            <Item
              sx={{
                flexBasis: { xs: '100%', sm: '50%', md: '40%' },
              }}>
              <Tooltip title="Student's Name">
                <Typography
                  variant={'h5'}
                  fontSize={14}
                  lineHeight={1.25}
                  mb={0}
                  noWrap>
                  {`${contact?.first_name} ${contact?.last_name}`}
                </Typography>
              </Tooltip>
            </Item>
            <Item
              sx={{
                flexBasis: { sm: '50%', md: '40%' },
                display: { xs: 'none', sm: 'block' },
              }}>
              <Tooltip title="Father's Name & Mother's Name">
                <Typography variant={'body1'} noWrap>
                  {contact?.father_name} & {contact?.mother_name}
                </Typography>
              </Tooltip>
            </Item>
            <Item
              sx={{
                flexBasis: { xs: '100%', sm: '50%', md: '25%' },
              }}>
              <Tooltip title="Class Section">
                <Typography
                  variant={'h5'}
                  fontSize={14}
                  lineHeight={1.25}
                  mb={0}
                  noWrap>
                  {`${contact?.class_section.class}`}
                </Typography>
              </Tooltip>
            </Item>
            <Item
              sx={{
                flexBasis: { xs: '100%', sm: '50%', md: '25%' },
              }}>
              <Tooltip title="Student's Gender">
                <Typography
                  variant={'h5'}
                  fontSize={14}
                  lineHeight={1.25}
                  mb={0}
                  noWrap>
                  {contact?.gender === '0' ? 'Male' : 'Female'}
                </Typography>
              </Tooltip>
            </Item>
            <Item
              sx={{
                flexBasis: { md: '25%' },
                display: { xs: 'none', md: 'block' },
              }}>
              <Tooltip title="Class Section Title">
                <Typography variant={'body1'} noWrap>
                  {contact?.class_section?.class_section_title}
                </Typography>
              </Tooltip>
            </Item>
            <Item
              sx={{
                flexBasis: { md: '25%' },
                display: { xs: 'none', md: 'block' },
              }}>
              <Tooltip title="Date of Birth">
                <Typography variant={'body1'} noWrap>
                  {contact?.date_of_birth}
                </Typography>
              </Tooltip>
            </Item>
            <Item
              sx={{
                flexBasis: { md: '25%' },
                display: { xs: 'none', md: 'block' },
              }}>
              <Tooltip title="Medium">
                <Typography variant={'body1'} noWrap>
                  {contact?.medium[0].toUpperCase() + contact?.medium.slice(1)}
                </Typography>
              </Tooltip>
            </Item>
          </Stack>
        }
      />
    </JumboListItem>
  );
};

export default StudentItem;
