import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';

import LoadingButton from '@mui/lab/LoadingButton';

import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Div from '@jumbo/shared/Div';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';

import { feeSchema, feeSchema2, feeSchema3 } from '../../utils/schema';
import { useCallback, useEffect, useState } from 'react';
import { getAllStudents } from 'app/store/features/studentsSlice';
import { getAllClasses } from 'app/store/features/classesSlice';
import JumboDropDown from '@jumbo/components/JumboFormik/JumboDropDown';
import JumboAutoComplete from '@jumbo/components/JumboFormik/JumboAutoComplete';
import { addNewFee } from 'app/store/features/feeSlice';

import {
  generateYears,
  getMonthsByYear,
  hasPermission,
} from 'app/utils/appHelpers';
import {
  Box,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { getAllBranches } from 'app/store/features/branchSlice';
import { PERMISSIONS, PERMISSION_TYPES } from 'app/utils/constants/permissions';

const RADIO_OPTIONS = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Branch',
    value: 'branch',
  },
];

const FeeForm = ({ fee, type }) => {
  const Swal = useSwalWrapper();
  const dispatch = useDispatch();
  const students = useSelector(state => state.students.students);
  const isStudentsFetched = useSelector(state => state.students.isDataFetched);

  const branches = useSelector(state => state.branches.branches);
  const isBranchesFetched = useSelector(state => state.branches.isDataFetched);

  const [selectedBranch, setSelectedBranch] = useState(null);
  const [filteredClassSections, setFilteredClassSections] = useState([]);

  const classSections = useSelector(state => state.classes.classes);
  const isClassSectionsFetched = useSelector(
    state => state.classes.isDataFetched,
  );

  const user = useSelector(state => state.auth.user);

  const [value, setValue] = useState('all');
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  const { hideDialog } = useJumboDialog();

  const YEARS = generateYears();

  useEffect(() => {
    if (selectedBranch) {
      setFilteredClassSections(
        classSections.filter(section => section.branch.id === selectedBranch),
      );
    }
  }, [selectedBranch, classSections]);

  const fetchData = useCallback(() => {
    if (!isStudentsFetched) dispatch(getAllStudents());
    if (!isBranchesFetched) {
      dispatch(getAllBranches());
    }
    if (!isClassSectionsFetched) {
      dispatch(getAllClasses());
    }
  }, [dispatch, isStudentsFetched, isBranchesFetched, isClassSectionsFetched]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const addFeeHandler = data => {
    let payload = data;
    if (type === 'regenerate') {
      payload = {
        student_id: data.student_ids,
        month: data.month,
        year: data.year,
        status: data.status,
      };
    } else {
      payload = {
        student_ids: data?.student_ids,
        month: data?.month,
        year: data?.year,
        status: data?.status,
      };
    }
    dispatch(addNewFee({ payload, type }));
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Fee has been added successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
    hideDialog();
  };

  const handleSubmit = data => {
    const studentsIds = data.student_ids.length
      ? students.filter(student => {
          const studentCreatedDate = new Date(student.createdAt);
          const year = studentCreatedDate.getFullYear();
          const month = studentCreatedDate.getMonth() + 1;
          if (data?.student_ids?.includes(student?.id))
            if (data?.year >= year && data?.month >= month) {
              return student;
            }
          return false;
        })
      : data;

    let payload = data;

    if (value === 'branch') {
      const student_ids = students
        .filter(student => {
          const studentCreatedDate = new Date(student.createdAt);
          const year = studentCreatedDate.getFullYear();
          const month = studentCreatedDate.getMonth() + 1;

          // Check if the student belongs to the selected branch
          const isInBranch = student?.branch?.id === data?.branch_id;

          // If class section is selected, filter by both branch and class section
          const isInClassSection =
            !data?.class_section_ids?.length ||
            data?.class_section_ids.includes(student?.class_section?.id);

          // Student must satisfy branch and optionally class section conditions
          if (isInBranch && isInClassSection) {
            if (data?.year >= year && data?.month >= month) {
              return student;
            }
          }
          return false;
        })
        .map(student => student.id);
      payload = { ...payload, student_ids };
    } else {
      payload = {
        ...payload,
        student_ids: Array.isArray(studentsIds)
          ? studentsIds.map(student => student.id)
          : studentsIds.student_ids,
      };
    }

    if (payload?.student_ids?.length < 1) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: `Selected students were not created at ${data?.month}-${data?.year}.`,
        showConfirmButton: false,
        timer: 3000,
      });
    } else {
      addFeeHandler(payload);
    }
  };

  if (!isStudentsFetched && fee?.id) {
    return (
      <Div
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          p: theme => theme.spacing(3),
          m: 'auto',
        }}>
        {' '}
        <CircularProgress />
      </Div>
    );
  }

  return (
    <>
      {!fee?.id && (
        <Box display={'flex'}>
          {RADIO_OPTIONS.map(option => (
            <RadioGroup
              key={option.value}
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group">
              <FormControlLabel
                value={option.value}
                control={
                  <Radio
                    checked={value === option.value}
                    onChange={() => {
                      setValue(option.value);
                    }}
                    value={option.value}
                  />
                }
                label={option.label}
              />
            </RadioGroup>
          ))}
        </Box>
      )}

      <Formik
        validateOnChange={true}
        initialValues={{
          student_ids: fee ? fee.student?.id : [],
          month: fee ? fee.month : currentMonth,
          year: fee ? fee.year : new Date().getFullYear(),
          status: fee ? fee.status : 'pending',
          branch_id: !hasPermission(
            [PERMISSIONS.BRANCH],
            [PERMISSION_TYPES.LISTING],
          )
            ? user?.branch_id
            : '',
          class_section_ids: fee ? fee.student?.class_section?.id : [],
        }}
        enableReinitialize={true}
        validationSchema={
          type === 'regenerate'
            ? feeSchema2
            : value === 'all'
              ? feeSchema
              : feeSchema3
        }
        onSubmit={handleSubmit}>
        {({ isSubmitting, values }) => (
          <Form noValidate autoComplete="off">
            <Div
              sx={{
                '& .MuiTextField-root': { mb: 3 },
              }}>
              {value === 'all' && (
                <JumboAutoComplete
                  fullWidth
                  size="small"
                  variant="outlined"
                  name="student_ids"
                  label="Student"
                  options={fee?.id ? [fee?.student] : students}
                  disabled={fee?.id}
                  isForStudents
                  defaultValue={fee?.id ? [fee?.student] : []}
                  isMulti
                />
              )}
              {value === 'branch' && (
                <>
                  <JumboDropDown
                    fullWidth
                    size="small"
                    variant="outlined"
                    name="branch_id"
                    label="Branch Name"
                    options={branches}
                    onChange={e => setSelectedBranch(e.target.value)} // <-- Update state on change
                    disabled={
                      !hasPermission(
                        [PERMISSIONS.BRANCH],
                        [PERMISSION_TYPES.LISTING],
                      )
                    }
                  />
                  <JumboAutoComplete
                    fullWidth
                    size="small"
                    variant="outlined"
                    name="class_section_ids"
                    label="Class Section"
                    options={
                      fee?.id
                        ? [fee?.student?.class_section]
                        : filteredClassSections
                    }
                    disabled={fee?.id}
                    isForClasses
                    defaultValue={fee?.id ? [fee?.student?.class_section] : []}
                    isMulti
                  />
                </>
              )}
              <JumboDropDown
                fullWidth
                size="small"
                variant="outlined"
                name="month"
                label="Month"
                options={getMonthsByYear(
                  values?.year ? values?.year : currentYear,
                )}
              />
              <JumboDropDown
                fullWidth
                size="small"
                variant="outlined"
                name="year"
                label="Year"
                options={YEARS}
              />
              <Div
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}>
                <LoadingButton
                  variant="outlined"
                  size="small"
                  sx={{ mr: 2 }}
                  onClick={() => hideDialog()}>
                  Close
                </LoadingButton>

                <LoadingButton
                  variant="contained"
                  size="small"
                  type="submit"
                  // loading={isSubmitting}
                  sx={{ mr: 2 }}>
                  Save
                </LoadingButton>
              </Div>
            </Div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FeeForm;
