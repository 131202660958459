import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Card,
} from '@mui/material';
import DatePicker from 'react-datepicker';

import Div from '@jumbo/shared/Div';
import DropDown from 'app/shared/components/DropDown';

import {
  addAttendance,
  getAllStudents,
  getAttendance,
} from 'app/store/features/studentsSlice';
import { getAllClasses } from 'app/store/features/classesSlice';
import { getAllBranches } from 'app/store/features/branchSlice';

import AttendanceRow from '../AttendanceRow';
import { showAlert } from 'app/store/features/alertSlice';
import { useNavigate } from 'react-router-dom';
import { isSuperAdmin } from 'app/utils/appHelpers';

const Attendance = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(state => state.auth.user);
  const storeStudents = useSelector(state => state.students.students);
  const isStoreStudentsFetched = useSelector(
    state => state.students.isDataFetched,
  );
  const storeClasses = useSelector(state => state.classes.classes);
  const isStoreClassesFetched = useSelector(
    state => state.classes.isDataFetched,
  );
  const storeBranches = useSelector(state => state.branches.branches);
  const isStoreBranches = useSelector(state => state.branches.isDataFetched);
  const attendanceRecords = useSelector(state => state.students.attendances);
  const isAttendanceFetched = useSelector(
    state => state.students.isAttendancesFetched,
  );

  const [branch, setBranch] = useState(!isSuperAdmin() ? user?.branch_id : '');
  const [selectedClass, setSelectedClass] = useState('');
  const [students, setStudents] = useState(storeStudents);
  const [attendace, setAttendance] = useState([]);
  const [attendanceDate, setAttendanceDate] = useState(new Date());

  const [filteredClasses, setFilteredClasses] = useState(storeClasses);

  const fetchData = useCallback(async () => {
    if (!isStoreStudentsFetched) {
      dispatch(getAllStudents());
    }
    if (!isStoreClassesFetched) {
      dispatch(getAllClasses());
    }
    if (!isStoreBranches && !user.branch) {
      dispatch(getAllBranches());
    }
    if (!isAttendanceFetched) dispatch(getAttendance());
  }, [
    isStoreStudentsFetched,
    isStoreClassesFetched,
    isStoreBranches,
    user.branch,
    dispatch,
    isAttendanceFetched,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const classes = storeClasses.filter(
      singleClass => singleClass?.branch?.id === branch,
    );
    const filteredStudents = storeStudents.filter(student => {
      const { branch_id, id } = student.class_section;
      if (branch_id === branch && id === selectedClass) return student;
      return null;
    });
    const filteredAttendanceRecords = attendanceRecords.filter(record => {
      return (
        record.class_section.id === selectedClass &&
        record.attendence_date ===
          attendanceDate.getFullYear() +
            '-' +
            String(attendanceDate.getMonth() + 1).padStart(2, '0') +
            '-' +
            String(attendanceDate.getDate()).padStart(2, '0') &&
        record.attendence_students.some(attendance =>
          filteredStudents.some(
            student => student.id === attendance.student_id,
          ),
        )
      );
    });

    // Associate attendance records with corresponding students
    const studentsWithAttendance = filteredStudents.map(student => {
      const studentAttendanceRecords = filteredAttendanceRecords.flatMap(
        record =>
          record.attendence_students.filter(
            attendenceStudent => attendenceStudent.student_id === student.id,
          ),
      );

      return { ...student, attendance: studentAttendanceRecords };
    });

    setStudents(studentsWithAttendance);
    /**
     * Check if attendanceRecords has any attendances entries for attendanceDate. If yes, filter out those classes who have
     * attendances records (because those classes have been marked) and show the rest.
     *
     * Only do this for user who is not super admin
     */

    let filteredOutClasses = classes;
    if (!isSuperAdmin()) {
      filteredOutClasses = classes.filter(classItem => {
        // Check if there are any attendance records for this class and the given attendanceDate
        const hasAttendanceRecord = attendanceRecords.some(
          record =>
            record.class_section.id === classItem.id &&
            record.attendence_date ===
              attendanceDate.getFullYear() +
                '-' +
                String(attendanceDate.getMonth() + 1).padStart(2, '0') +
                '-' +
                String(attendanceDate.getDate()).padStart(2, '0'),
        );

        // If the class does not have an attendance record for the date, include it in the result
        return !hasAttendanceRecord;
      });
    }

    setFilteredClasses(filteredOutClasses);
  }, [
    branch,
    storeClasses,
    selectedClass,
    storeBranches,
    storeStudents,
    attendanceRecords,
    attendanceDate,
  ]);

  const handleAttendance = (student, status) => {
    let isAlreadyPresent = attendace.filter(
      attende => attende.student_id === student.id,
    );
    if (isAlreadyPresent.length) {
      isAlreadyPresent = isAlreadyPresent[0];
      switch (status) {
        case 'present':
          isAlreadyPresent.is_present = true;
          isAlreadyPresent.is_excused = false;
          isAlreadyPresent.is_unexcused = false;
          isAlreadyPresent.late_comer = false;
          break;
        case 'absent':
          isAlreadyPresent.is_present = false;
          isAlreadyPresent.is_excused = false;
          isAlreadyPresent.is_unexcused = true;
          isAlreadyPresent.late_comer = false;
          break;
        case 'excused':
          isAlreadyPresent.is_present = false;
          isAlreadyPresent.is_excused = true;
          isAlreadyPresent.is_unexcused = false;
          isAlreadyPresent.late_comer = false;
          break;
        case 'late':
          isAlreadyPresent.is_present = false;
          isAlreadyPresent.is_excused = false;
          isAlreadyPresent.is_unexcused = false;
          isAlreadyPresent.late_comer = true;
          break;
        default:
          break;
      }
      const nonClicked = attendace.filter(
        attende => attende.student_id !== student.id,
      );
      setAttendance([...nonClicked, isAlreadyPresent]);
      return;
    }
    const attendaceStatus = {
      student_id: student.id,
      is_present: false,
      is_excused: false,
      is_unexcused: false,
      late_comer: false,
    };
    switch (status) {
      case 'present':
        attendaceStatus.is_present = true;
        break;
      case 'absent':
        attendaceStatus.is_unexcused = true;
        break;
      case 'excused':
        attendaceStatus.is_excused = true;
        break;
      case 'late':
        attendaceStatus.late_comer = true;
        break;
      default:
        break;
    }

    setAttendance(prev => [...prev, attendaceStatus]);
  };

  const submitAttendance = async () => {
    if (!attendanceDate) {
      dispatch(
        showAlert({ type: 'error', message: 'Please add Attendance Date' }),
      );
      return;
    }

    const payload = {
      class_section_id: selectedClass,
      attendence_date: moment(attendanceDate).format('YYYY-MM-DD'),
      attendence_students: attendace,
    };

    await dispatch(addAttendance(payload)).unwrap();
    navigate('/app/attendance');
  };

  return (
    <Card sx={{ padding: 4 }}>
      <Div
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 2,
        }}>
        <Typography
          color="primary.main"
          textAlign="center"
          variant="h3"
          width={'100%'}>
          Add Attendance
        </Typography>
      </Div>
      <Div
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <DropDown
          options={storeBranches}
          value={branch}
          label="Select Branch"
          onChange={e => setBranch(e.target.value)}
          sx={{ width: '45%' }}
          disabled={!isSuperAdmin()}
        />
        <DropDown
          options={filteredClasses}
          value={selectedClass}
          label="Select Class"
          onChange={e => setSelectedClass(e.target.value)}
          sx={{ width: '45%' }}
        />
      </Div>
      <Div>
        <>
          {!!students.length && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}>
              <Button
                variant="contained"
                sx={{ marginTop: 1 }}
                onClick={submitAttendance}>
                Add Attendance
              </Button>
            </div>
          )}
          <Div
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              marginBottom: 2,
              marginTop: 2,
              width: '100%',
            }}>
            <div>
              <p>Attendance Date</p>
              <DatePicker
                selected={attendanceDate}
                value={attendanceDate}
                onChange={value => setAttendanceDate(value)}
                placeholder="Attendance Date"
                maxDate={new Date()}
              />
            </div>
          </Div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Father Name</TableCell>
                  <TableCell align="center">Roll No.</TableCell>
                  <TableCell align="center">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {students.map((student, index) => (
                  <AttendanceRow
                    key={student.id + index}
                    student={student}
                    handleAttendance={handleAttendance}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      </Div>
    </Card>
  );
};

export default Attendance;
